.checkmarkIcon {
  --color-primary: var(--lightBlue);
  --color-secondary: var(--black);
}

.closeButton {
  padding: 0;
  cursor: pointer;
  background: none;
  border: none;
}

@use '@styles/functions' as functions;
@use '@styles/_variables.scss' as variables;
@use '@styles/mixins/_breakpoints.scss' as breakpoints;

.badgeContainer {
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  z-index: variables.$z-index-lv4;
  padding-top: 0;

  @include breakpoints.up('1025px') {
    margin-top: functions.spacing(4);
    padding-top: functions.spacing(8);
    padding-left: 80px;
  }
}

.primaryBadge {
  font-size: functions.spacing(3.5);
  font-weight: 700;
  line-height: functions.spacing(5.25);
  text-transform: uppercase;

  @include breakpoints.up(sm) {
    text-align: center;
    padding-bottom: 8;
  }

  @include breakpoints.down(391) {
    font-size: functions.spacing(2.5);
    line-height: functions.spacing(4);
  }
}

.secondaryBadges {
  font-size: 0.625rem;
  line-height: 1.6rem;

  @include breakpoints.up(sm) {
    text-align: center;
  }
}

.omnichannelContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.omnichannelCta {
  position: static;
  cursor: pointer;

  &::after {
    content: '';
    z-index: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.omnichannelParsedContent {
  font-size: 0.75rem;

  p {
    margin-block: 0;
  }

  a {
    position: relative;
    z-index: 1;
    cursor: pointer;
    color: var(--text-primary-dark);
  }
}

.omnichannelInfoWrapper {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 16px;
  text-align: left;
}

.omnichannelHeading {
  font-weight: bold;
  text-transform: uppercase;
}

@use '@styles/functions' as functions;
@use '@styles/mixins/_breakpoints.scss' as breakpoints;

.footer {
  color: var(--text-primary-dark);
  display: flex;
  justify-content: space-between;

  > a {
    padding-top: 0;
  }
}

.wishlistContainer {
  align-items: flex-start;
  background: none;
  display: flex;
  height: 16px;
  margin-right: functions.spacing(1);
  text-align: right;
  width: 20px;
}

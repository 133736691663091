@use '@styles/functions' as func;
@use '@styles/mixins/breakpoints' as breakpoints;

.container {
  font-size: func.px-to-rem(14px);
  display: flex;
  gap: func.px-to-rem(10px);
  align-items: center;
  margin: func.px-to-rem(5px) 0;
  line-height: 1.25;
}

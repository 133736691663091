.wrapper {
  margin-bottom: 16px;

  :global(.swiper-button-prev) {
    left: 16px;
  }

  :global(.swiper-button-next) {
    right: 16px;
  }

  :global(.swiper-button-prev),
  :global(.swiper-button-next) {
    color: var(--black);
    width: 0;
  }

  :global(.swiper-button-prev::after),
  :global(.swiper-button-next::after) {
    font-size: 14px;
    font-weight: bold;
  }
}

.swiperWrapper {
  text-align: center;
  width: 100%;
  height: 100%;

  .swiperSlide {
    height: auto;
  }
}

.itemContainer {
  height: 100%;
  font-size: 14px;
  color: var(--black);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  gap: 4px;
  min-height: 62px;

  svg {
    height: 24px;
    width: 24px;
    margin-right: 16px;
  }
}

.swiperContainer {
  padding-inline: 20px;
}

.tooltip {
  display: flex;
}

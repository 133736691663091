@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as func;
@use '@styles/variables' as variables;

.container {
  align-items: center;
  background-color: white;
  border-radius: func.spacing(4, 4, 0, 0);
  box-shadow: 0 -3px 20px -2px rgba(0, 0, 0, 0.2);
  bottom: 0;
  color: var(--text-primary-dark);
  display: flex;
  height: func.spacing(18);
  justify-content: space-between;
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: func.spacing(1, 2);
  pointer-events: none;
  position: fixed;
  transition: opacity 0.1s ease-in-out;
  width: 100vw;
  z-index: variables.$z-index-lv2;

  &.visible {
    opacity: 1;
    pointer-events: inherit;
  }

  @include breakpoints.up(sm) {
    height: func.spacing(22);
    padding: func.spacing(1, 8, 1, 4);
  }

  @include breakpoints.up(1025px) {
    height: func.spacing(22);
    padding: func.spacing(2, 16);
  }

  @include breakpoints.down(sm) {
    background: var(--wildSand);
    height: fit-content;
    padding: func.spacing(4);
    padding-bottom: 0;
  }

  img {
    @include breakpoints.up(sm) {
      flex: 0 1 auto;
    }
  }
}

.imageAndPriceContainer {
  align-items: center;
  display: flex;
  gap: func.spacing(6);
  height: 100%;
  justify-content: space-between;

  @include breakpoints.down(sm) {
    flex-direction: column;
    gap: func.spacing(2);
    width: 100%;

    button {
      font-size: 0.75rem;
      height: func.spacing(10);
    }
  }

  img {
    display: none;
    width: auto;

    @include breakpoints.up(sm) {
      display: block;
    }

    @include breakpoints.down(sm) {
      height: unset;
    }
  }
}
.priceContainer {
  align-items: center;
  display: flex;
  gap: func.spacing(6);
  width: 100%;

  @include breakpoints.down(sm) {
    flex-direction: row-reverse;
    gap: 0;
    justify-content: space-between;
  }
}

.priceContainerWithError {
  @include breakpoints.down(sm) {
    .priceInnerContainer {
      justify-content: flex-end;
      width: auto;
    }
  }

  @include breakpoints.between(sm, 920px) {
    flex-direction: column;

    :global(.product-price) {
      padding-bottom: 0;
    }
  }
}

.priceInnerContainer {
  align-items: normal;
  display: flex;
  flex-direction: column;
  gap: func.spacing(2);
  justify-content: flex-start;
  min-width: fit-content;
  margin-bottom: -(func.spacing(4));
  width: 100%;

  @include breakpoints.down(sm) {
    width: 100%;

    :global(.product-price) {
      margin-right: func.spacing(1.5);
    }
  }
}

.errorContainer {
  min-width: 206px;
}

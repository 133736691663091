@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;

.textContainer {
  margin-bottom: functions.spacing(4);

  @include breakpoints.up('md') {
    margin-top: 0;
  }
}

.titleContainer {
  text-align: center;
}

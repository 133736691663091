.pdpSubscriptionSection {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 8px 20px;
  color: var(--text-primary-dark);
  font-size: 0.875rem;
  border: 1px solid;
  border-color: var(--mediumGray);
  background-color: transparent;

  &.selected {
    border: 2px solid;
    background-color: var(--wildSand);
    border-color: var(--boulder);
  }
}

.benefitsSection {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;

    svg {
      margin-right: 8px;
    }
    span {
      margin-right: 12px;
    }
  }
}

.priceLabelWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectionTitle {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 16px;
}

.itemSeparator {
  grid-column: span 3;
  height: 1px;
  background-color: var(--alto);
}

.headerSection {
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  .MuiFormControlLabel-root {
    margin-bottom: 0;
  }

  span {
    justify-content: start;
  }

  label span {
    font-weight: bold;
  }
}

.pricePerBoxWrapper {
  color: var(--text-primary-dark);
  font-size: 0.875rem;
  margin-top: 1px;
  display: flex;
  justify-content: flex-end;

  &.pdp {
    justify-content: flex-start;
    margin-bottom: 12px;
  }
  > span {
    margin-right: 4px;

    &:last-of-type {
      font-weight: 700;
    }
  }
}

.quantityLabel {
  margin-top: 16px;
  font-size: 1rem;
}

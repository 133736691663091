@use '@styles/functions' as func;
@use '@styles/mixins/breakpoints' as breakpoints;

$external-margin: func.px-to-rem(32px);

.container {
  width: 100%;
  padding: func.px-to-rem(20px);
  background-color: #f5f5f5;
  margin: 0;
  margin-bottom: func.px-to-rem(4px);

  @include breakpoints.down(sm) {
    width: calc(100% + $external-margin * 2);
    margin-left: -$external-margin;
    margin-right: -$external-margin;
    padding-left: $external-margin;
    padding-right: $external-margin;
  }
}

.clAccessoriesContainer {
  display: flex;
  flex-direction: column;
  gap: func.spacing(4);

  &.soldOut {
    background-color: var(--ultraLightGray);
    padding: func.spacing(5, 4);
  }
}

.clAccessoriesErrorContainer {
  color: var(--error);
  font-size: 0.875rem;

  &:global(.error-container) {
    gap: 0;
    text-align: left;
  }

  span {
    font-weight: 400;
  }
}

.quantityWrapper {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;

  span {
    margin-right: 8px;
  }

  > div {
    width: 120px;
    padding: 1px;

    button {
      background-color: var(--white);
    }
  }

  .quantityLabel {
    color: var(--text-primary-dark);
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: uppercase;
    width: auto;
    margin-right: 5px;
    @include breakpoints.down(sm) {
      line-break: loose;
      hyphens: auto;
      overflow: hidden;
      overflow-wrap: break-word;
      text-overflow: ellipsis;
      white-space: break-spaces;
    }
  }
}

.contactLensesSeparator {
  grid-column: span 3;
  height: 1px;
  background-color: var(--background-tertiary-light);
}

.styledSeparator {
  height: 1px;
  margin: 0;
  border: none;
  align-self: stretch;
  flex-grow: 0;
  background-color: var(--background-tertiary-light);
}
